import React from "react"
import Layout from "../components/layout"
import SeasonList from "../components/season-list"

export default function Seasons({ data, active }) {
  return (
    <Layout>
      <SeasonList />
    </Layout>
  )
}
